
          const pug = require('pug-runtime');
          module.exports = function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;var pug_debug_filename, pug_debug_line;try {;
    var locals_for_with = (locals || {});
    
    (function (JSON, Object, productData, products) {
      ;pug_debug_line = 1;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
if (productData !== undefined) {
;pug_debug_line = 2;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
var products = productData;
;pug_debug_line = 3;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"grid-table sortable\"\u003E";
;pug_debug_line = 4;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"table-controls\"\u003E";
;pug_debug_line = 5;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"inner\"\u003E";
;pug_debug_line = 6;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
let categories=Object.keys(products[0].data).map(idx => products[0].data[idx].label)
;pug_debug_line = 7;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Clabel for=\"category-sort\"\u003E";
;pug_debug_line = 7;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "Sort By:\u003C\u002Flabel\u003E";
;pug_debug_line = 8;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cselect class=\"category-sort\"\u003E";
;pug_debug_line = 9;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
// iterate products[0].data
;(function(){
  var $$obj = products[0].data;
  if ('number' == typeof $$obj.length) {
      for (var idx = 0, $$l = $$obj.length; idx < $$l; idx++) {
        var option = $$obj[idx];
;pug_debug_line = 10;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Coption" + (pug.attr("value", JSON.stringify(option), true, false)+pug.attr("selected", option.default, true, false)+pug.attr("data-idx", idx, true, false)) + "\u003E";
;pug_debug_line = 10;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + (pug.escape(null == (pug_interp = option.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var idx in $$obj) {
      $$l++;
      var option = $$obj[idx];
;pug_debug_line = 10;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Coption" + (pug.attr("value", JSON.stringify(option), true, false)+pug.attr("selected", option.default, true, false)+pug.attr("data-idx", idx, true, false)) + "\u003E";
;pug_debug_line = 10;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + (pug.escape(null == (pug_interp = option.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
;pug_debug_line = 11;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cselect class=\"sort-direction\"\u003E";
;pug_debug_line = 12;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Coption selected=\"selected\" value=\"true\"\u003E";
;pug_debug_line = 12;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "asc\u003C\u002Foption\u003E";
;pug_debug_line = 13;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Coption value=\"false\"\u003E";
;pug_debug_line = 13;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "desc     \u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
;pug_debug_line = 14;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"table-header\"\u003E";
;pug_debug_line = 15;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"table-header-row table-row header-header\"\u003E";
;pug_debug_line = 16;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"table-header-cell table-cell source-header\" colspan=\"3\"\u003E";
;pug_debug_line = 17;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp\u003E";
;pug_debug_line = 17;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "Source\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
;pug_debug_line = 18;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"table-header-cell table-cell details-header\" colspan=\"5\"\u003E";
;pug_debug_line = 19;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp\u003E";
;pug_debug_line = 19;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "Deets\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
;pug_debug_line = 20;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"table-header-cell table-cell score-header\" colspan=\"4\"\u003E";
;pug_debug_line = 21;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp\u003E";
;pug_debug_line = 21;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "Scores\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
;pug_debug_line = 22;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"table-header-row table-row\"\u003E";
;pug_debug_line = 23;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
// iterate products[0].data
;(function(){
  var $$obj = products[0].data;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var header = $$obj[pug_index1];
;pug_debug_line = 24;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["table-header-cell","table-cell",(header.mobile ? header.type : header.type + " hide-mobile")+(header.score ? " score" : "")], [false,false,true]), false, false)) + "\u003E";
;pug_debug_line = 25;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp\u003E";
;pug_debug_line = 25;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + (null == (pug_interp = header.label) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var header = $$obj[pug_index1];
;pug_debug_line = 24;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["table-header-cell","table-cell",(header.mobile ? header.type : header.type + " hide-mobile")+(header.score ? " score" : "")], [false,false,true]), false, false)) + "\u003E";
;pug_debug_line = 25;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp\u003E";
;pug_debug_line = 25;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + (null == (pug_interp = header.label) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
;pug_debug_line = 26;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv class=\"table-body\"\u003E";
;pug_debug_line = 27;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
// iterate products
;(function(){
  var $$obj = products;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var product = $$obj[pug_index2];
;pug_debug_line = 28;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
if (product.data[0].value!='') {
;pug_debug_line = 29;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Ca" + (" class=\"table-body-row table-row row-link\""+pug.attr("href", "https://dispensaryadventures.com" + product.url, true, false)) + "\u003E";
;pug_debug_line = 30;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
// iterate product.data
;(function(){
  var $$obj = product.data;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var item = $$obj[pug_index3];
;pug_debug_line = 31;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["table-body-cell","table-cell",(item.mobile ? item.type : item.type + " hide-mobile")+(item.score ? " score" : "")], [false,false,true]), false, false)) + "\u003E";
;pug_debug_line = 32;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp" + (pug.attr("class", pug.classes([item.label.toLowerCase().replace(/\W/g, '')], [true]), false, false)) + "\u003E";
;pug_debug_line = 32;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + (null == (pug_interp = item.value) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var item = $$obj[pug_index3];
;pug_debug_line = 31;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["table-body-cell","table-cell",(item.mobile ? item.type : item.type + " hide-mobile")+(item.score ? " score" : "")], [false,false,true]), false, false)) + "\u003E";
;pug_debug_line = 32;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp" + (pug.attr("class", pug.classes([item.label.toLowerCase().replace(/\W/g, '')], [true]), false, false)) + "\u003E";
;pug_debug_line = 32;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + (null == (pug_interp = item.value) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fa\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var product = $$obj[pug_index2];
;pug_debug_line = 28;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
if (product.data[0].value!='') {
;pug_debug_line = 29;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Ca" + (" class=\"table-body-row table-row row-link\""+pug.attr("href", "https://dispensaryadventures.com" + product.url, true, false)) + "\u003E";
;pug_debug_line = 30;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
// iterate product.data
;(function(){
  var $$obj = product.data;
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var item = $$obj[pug_index4];
;pug_debug_line = 31;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["table-body-cell","table-cell",(item.mobile ? item.type : item.type + " hide-mobile")+(item.score ? " score" : "")], [false,false,true]), false, false)) + "\u003E";
;pug_debug_line = 32;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp" + (pug.attr("class", pug.classes([item.label.toLowerCase().replace(/\W/g, '')], [true]), false, false)) + "\u003E";
;pug_debug_line = 32;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + (null == (pug_interp = item.value) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var item = $$obj[pug_index4];
;pug_debug_line = 31;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["table-body-cell","table-cell",(item.mobile ? item.type : item.type + " hide-mobile")+(item.score ? " score" : "")], [false,false,true]), false, false)) + "\u003E";
;pug_debug_line = 32;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp" + (pug.attr("class", pug.classes([item.label.toLowerCase().replace(/\W/g, '')], [true]), false, false)) + "\u003E";
;pug_debug_line = 32;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + (null == (pug_interp = item.value) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fa\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
;pug_debug_line = 34;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "\u003Cp\u003E";
;pug_debug_line = 34;pug_debug_filename = "\u002Fopt\u002Fbuild\u002Frepo\u002Fassets\u002Fscripts\u002Fsort-table\u002Ftable.pug";
pug_html = pug_html + "No products to display\u003C\u002Fp\u003E";
}
    }.call(this, "JSON" in locals_for_with ?
        locals_for_with.JSON :
        typeof JSON !== 'undefined' ? JSON : undefined, "Object" in locals_for_with ?
        locals_for_with.Object :
        typeof Object !== 'undefined' ? Object : undefined, "productData" in locals_for_with ?
        locals_for_with.productData :
        typeof productData !== 'undefined' ? productData : undefined, "products" in locals_for_with ?
        locals_for_with.products :
        typeof products !== 'undefined' ? products : undefined));
    ;} catch (err) {pug.rethrow(err, pug_debug_filename, pug_debug_line);};return pug_html;};
        